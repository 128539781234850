// Read environment variables from "testenv" in the repository root. Override environment vars if they are already set.
/* jshint esversion: 6 */
/* jshint -W117 */
const CLIENT_ID = process.env.CLIENT_ID || process.env.REACT_APP_CLIENT_ID;
const ISSUER = process.env.ISSUER || process.env.REACT_APP_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = '';
// BASENAME includes trailing slash
const REDIRECT_URI = `${process.env.REACT_APP_BASE_URL}/login/callback/`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: true,
  },
  resourceServer: {
    messagesUrl: `${process.env.REACT_APP_BASE_URL}/api/messages`,
  },
  app: {
    basename: BASENAME,
  },
};
