import React from 'react';
import styles from './Loader.module.scss';
interface LoaderProps {
  message?: string | null;
}

const Loader: React.FC<LoaderProps> = ({ message = null }) => {
  return (
    <div className={styles.loader}>
      <div className={styles.loader_container}>
        <span className={styles.spinner}></span>
        {message && <p className={styles.loaderMessage}>{message}</p>}
      </div>
    </div>
  );
};

export default Loader;
