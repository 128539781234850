import React, { useEffect, useState } from 'react';
import styles from './LightSettings.module.scss';
import { Filters } from 'components/RightSidebar/RightSidebar';
import GlobalStrings from 'utils/globalStrings';
import ReactSlider from 'react-slider';
import sphere from 'assets/images/sphere.svg';
import SelectWithoutForm from 'components/SelectWithoutForm/SelectWithoutForm';
import { SelectOption } from 'utils/types';
import hdriPlaceholder from 'assets/images/HDRI_placeholder.png';
import interiorCool from 'assets/images/HDRI_interior_cool.png';
import { data } from 'utils/data';
import classNames from 'classnames';

const HDRIOptions = [
  {
    id: 1,
    value: GlobalStrings['Interior Warm'],
    label: GlobalStrings['Interior Warm'],
    img: hdriPlaceholder,
  },
  {
    id: 2,
    value: GlobalStrings['Interior Cool'],
    label: GlobalStrings['Interior Cool'],
    img: interiorCool,
  },
  {
    id: 3,
    value: GlobalStrings['Exterior Warm'],
    label: GlobalStrings['Exterior Warm'],
    img: hdriPlaceholder,
  },
  {
    id: 4,
    value: GlobalStrings['Exterior Cool'],
    label: GlobalStrings['Exterior Cool'],
    img: hdriPlaceholder,
  },
];

const renderThumb = (props: any, state: any) => (
  <div {...props}>
    <div className={styles.current_value}>{state.valueNow}</div>
  </div>
);

const GenerateSliders = (props: {
  data: {
    id: number;
    label: string;
    renderThumb: (props: any, state: any) => React.JSX.Element;
    onChange: (newValue: any) => void;
    value: number;
    min: number;
    max: number;
    marks: number[];
    renderMark: (props: any) => React.JSX.Element;
    withNumbers: boolean;
  }[];
  label?: string;
  children?: React.JSX.Element;
}) => {
  return (
    <div className={styles.inner_container}>
      {props.label && <p className={styles.slider_label}>{props.label}</p>}
      {props.children}
      {props.data.map((item) => {
        return (
          <div key={item.id}>
            <p className={styles.slider_label}>{item.label}</p>

            <div
              className={classNames(styles.slider_container, {
                [styles.with_numbers]: item.withNumbers,
              })}
            >
              <ReactSlider
                className={styles.slider}
                thumbClassName={styles.thumb}
                thumbActiveClassName={styles.active_thumb}
                trackClassName={styles.track}
                markClassName={styles.mark}
                renderThumb={item.renderThumb}
                onChange={item.onChange}
                value={item.value}
                min={item.min}
                max={item.max}
                marks={item.marks}
                renderMark={item.renderMark}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const LightSettings = (props: {
  filters: Filters | undefined;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | Function;
}) => {
  const [keyLightExposure, setKeyLightExposure] = useState(props.filters?.lightSettings?.keyLightExposure);
  const [HDRILightExposure, setHDRILightExposure] = useState(props.filters?.lightSettings?.HDRILightExposure);
  const [keyLight, setKeyLight] = useState(props.filters?.lightSettings?.keyLight);
  const [HDRILighting, setHDRILighting] = useState(props.filters?.lightSettings?.HDRILighting);
  const [keyLightHeight, setKeyLightHeight] = useState(props.filters?.lightSettings?.keyLightHeight);
  const [keyLightRotation, setkeyLightRotation] = useState(props.filters?.lightSettings?.keyLightRotation);

  const keyLightExposureOptions = [
    {
      id: 1,
      label: GlobalStrings['Key Light Intensity'],
      renderThumb,
      onChange: (newValue: any) => setKeyLightExposure(newValue),
      value: keyLightExposure ?? 0,
      min: 0,
      max: 1500,
      marks: [0, 1650],
      renderMark: (props: any) => {
        const order = props?.key;
        return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
      },
      withNumbers: true,
    },
    {
      id: 2,
      label: GlobalStrings['HDRI Light Intensity'],
      renderThumb,
      onChange: (newValue: any) => setHDRILightExposure(newValue),
      value: HDRILightExposure ?? 0,
      min: 0,
      max: 1500,
      marks: [0, 1650],
      renderMark: (props: any) => {
        const order = props?.key;
        return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
      },
      withNumbers: true,
    },
  ];
  const temperatureSliders = [
    {
      id: 1,
      label: GlobalStrings['Key Light'],
      renderThumb,
      onChange: (newValue: any) => setKeyLight(newValue),
      value: keyLight ?? data.defaultValues.lightSettings.keyLight,
      min: 3000,
      max: 10000,
      marks: [3000, 10800],
      renderMark: (props: any) => {
        const order = props?.key;
        return (
          <span {...props}>
            {+order > 3000 ? +order - 800 : order}
            {'K'}
          </span>
        );
      },
      withNumbers: true,
    },
    {
      id: 2,
      label: GlobalStrings['HDRI Lighting'],
      renderThumb,
      onChange: (newValue: any) => setHDRILighting(newValue),
      value: HDRILighting ?? data.defaultValues.lightSettings.HDRILighting,
      min: 3000,
      max: 10000,
      marks: [3000, 10800],
      renderMark: (props: any) => {
        const order = props?.key;
        return (
          <span {...props}>
            {+order > 3000 ? +order - 800 : order}
            {'K'}
          </span>
        );
      },
      withNumbers: true,
    },
  ];
  const keyLightOptions = [
    {
      id: 1,
      label: GlobalStrings['Key Light Height'],
      renderThumb,
      onChange: (newValue: any) => setKeyLightHeight(newValue),
      value: keyLightHeight ?? 0,
      min: 0,
      max: 180,
      marks: [0, 200],
      renderMark: (props: any) => {
        const order = props?.key;
        return (
          <span {...props}>
            {+order > 0 ? +order - 20 : order}
            {'°'}
          </span>
        );
      },
      withNumbers: true,
    },
    {
      id: 2,
      label: GlobalStrings['Key Light Rotation'],
      renderThumb,
      onChange: (newValue: any) => setkeyLightRotation(newValue),
      value: keyLightRotation ?? 0,
      min: 0,
      max: 360,
      marks: [0, 400],
      renderMark: (props: any) => {
        const order = props?.key;
        return (
          <span {...props}>
            {+order > 0 ? +order - 40 : order}
            {'°'}
          </span>
        );
      },
      withNumbers: true,
    },
  ];

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          lightSettings: {
            ...prev?.lightSettings,
            keyLightExposure,
            HDRILightExposure,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [keyLightExposure, HDRILightExposure]);

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          lightSettings: {
            ...prev?.lightSettings,
            keyLight,
            HDRILighting,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [keyLight, HDRILighting]);

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          lightSettings: {
            ...prev?.lightSettings,
            keyLightHeight,
            keyLightRotation,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [keyLightHeight, keyLightRotation]);

  const resetHandler = () => {
    setKeyLightExposure(data.defaultValues.lightSettings.keyExposure);
    setHDRILightExposure(data.defaultValues.lightSettings.HDRIExposure);
    setKeyLight(data.defaultValues.lightSettings.keyLight);
    setHDRILighting(data.defaultValues.lightSettings.HDRILighting);
    setKeyLightHeight(data.defaultValues.lightSettings.keyLightHeight);
    setkeyLightRotation(data.defaultValues.lightSettings.keyLightRotation);
    props.setFilters((prev: any) => {
      return {
        ...prev,
        lightSettings: {
          keyLightExposure: data.defaultValues.lightSettings.keyExposure,
          HDRILightExposure: data.defaultValues.lightSettings.HDRIExposure,
          keyLight: data.defaultValues.lightSettings.keyLight,
          HDRILighting: data.defaultValues.lightSettings.HDRILighting,
          keyLightHeight: data.defaultValues.lightSettings.keyLightHeight,
          keyLightRotation: data.defaultValues.lightSettings.keyLightRotation,
          HDRI: data.defaultValues.lightSettings.HDRI,
        },
      };
    });
  };

  const HDRIHandler = (option: SelectOption) => {
    props.setFilters((prev: any) => {
      return {
        ...prev,
        lightSettings: {
          ...prev?.lightSettings,
          HDRI: option?.value,
        },
      };
    });
  };

  return (
    <div className={styles.container}>
      <GenerateSliders data={keyLightExposureOptions} label={GlobalStrings.Intensity} />
      <GenerateSliders data={temperatureSliders} label={GlobalStrings.Temperature} />
      <GenerateSliders data={keyLightOptions} label={GlobalStrings.Angle}>
        <div className={styles.image}>
          <img src={sphere} alt="angle" />
        </div>
      </GenerateSliders>

      <div className={styles.hdri}>
        <p>{GlobalStrings['Change HDRI']}</p>
        <SelectWithoutForm
          label={GlobalStrings['Select an option']}
          options={HDRIOptions}
          value={props.filters?.lightSettings?.HDRI}
          setValue={HDRIHandler}
        />
        <div className={styles.image}>
          <img
            src={HDRIOptions?.find((item) => item.value === props.filters?.lightSettings?.HDRI)?.img ?? hdriPlaceholder}
            alt="hdri"
          />
        </div>
      </div>

      <div className={styles.buttons_container}>
        <button className={styles.btn} onClick={resetHandler}>
          {GlobalStrings.Reset}
        </button>
        <button className={styles.btn} onClick={() => props.setIsOpen(false)}>
          {GlobalStrings.OK}
        </button>
      </div>
    </div>
  );
};

export default LightSettings;
