import React from 'react';

import { Outlet } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import { SnackbarProvider } from 'notistack';

const Layout = () => {
  return (
    <div>
      <SnackbarProvider autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <div className="container">
          <Outlet />
        </div>
        <Footer />
      </SnackbarProvider>
    </div>
  );
};

export default Layout;
