import React from 'react';

import styles from './Footer.module.scss';
import GlobalStrings from 'utils/globalStrings';

const Footer = () => {
  return (
    <section className={styles.footer}>
      <p>{GlobalStrings.footerText}</p>
    </section>
  );
};

export default Footer;
