import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import styles from './SigninForm.module.scss';
import GlobalStrings from 'utils/globalStrings';
import parse from 'html-react-parser';
import { Button } from 'semantic-ui-react';
import { UserClaims } from '@okta/okta-auth-js';

const SigninForm = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);

  useEffect(() => {
    if (!authState) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((userInfo) => {
          setUserInfo(userInfo);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth, userInfo]); // Update if authState changes

  const loginOkta = async () => oktaAuth.signInWithRedirect();

  return (
    <div className={styles.signin}>
      <div className={styles.signin_container}>
        <h1>{parse(GlobalStrings["Let's create some magic"])}</h1>
        <p>{GlobalStrings['Sign in']}</p>
        <div className={styles.form_group}>
          <Button id="login-button" primary onClick={loginOkta} className={styles.button}>
            Login with OKTA
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SigninForm;
