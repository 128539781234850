import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { ReactSVG } from 'react-svg';
import closeIcon from 'assets/icons/close_icon.svg';
import infoIcon from 'assets/icons/info_icon.svg';
import classNames from 'classnames';

const TooltipPopup = (props: {
  trigger: React.JSX.Element | ((isOpen: boolean) => React.JSX.Element);
  content: React.ReactNode;
  position: PopupPosition | PopupPosition[];
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  headerTitle?: string;
  tooltipMessage?: string;
}) => {
  const [displayTooltipMessage, setDisplayTooltipMessage] = useState(false);
  return (
    <Popup
      trigger={props.trigger}
      position={props.position}
      closeOnDocumentClick
      open={props.isOpen}
      onOpen={() => (props.setIsOpen ? props.setIsOpen(true) : undefined)}
      repositionOnResize
    >
      <div className="card">
        {props.headerTitle && (
          <div className="header">
            <p>{props.headerTitle}</p>

            <div className="icons">
              <ReactSVG
                src={infoIcon}
                className={classNames('icon', {
                  active: displayTooltipMessage,
                })}
                onClick={() => setDisplayTooltipMessage((prev) => !prev)}
              />
              <ReactSVG
                src={closeIcon}
                className="icon"
                onClick={() => (props?.setIsOpen ? props?.setIsOpen(false) : undefined)}
              />
            </div>
          </div>
        )}
        {displayTooltipMessage && props.tooltipMessage ? (
          <p className="tooltip_message">{props.tooltipMessage}</p>
        ) : (
          props.content
        )}
      </div>
    </Popup>
  );
};

export default TooltipPopup;
