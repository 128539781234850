import React, { useEffect, useMemo, useState } from 'react';
import { RightSidebar, Sidebar } from 'components';
import Moment from 'react-moment';
import arrowIcon from 'assets/icons/arrow-icon.svg';
import styles from './AssetGeneration.module.scss';
import { Link, useSearchParams } from 'react-router-dom';
import GlobalStrings from 'utils/globalStrings';
import { Filters } from 'components/RightSidebar/RightSidebar';
import { data } from 'utils/data';
import { getFromLocalStorage, checkTenantId } from '../../utils/globalFunctions';
import { useOktaAuth } from '@okta/okta-react';
import axios, { AxiosInstance } from 'axios';
interface DataExport {
  product_name: string;
  region_name: string;
  product_label: string;
  ai_type: string;
  meal_type: string;
  prompt: string;
  negative_prompt: string;
  timestamp: string;
}
const AssetGeneration = () => {
  const [imageURL, setImageURL] = useState('');
  const user = getFromLocalStorage('user');
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const [dataStreaming, setDataStreaming] = useState('');
  const [dataExport, setDataExport] = useState<DataExport>({
    product_name: '',
    region_name: '',
    product_label: '',
    ai_type: '',
    meal_type: '',
    prompt: '',
    negative_prompt: '',
    timestamp: '',
  });
  const [accentColor, setAccentColor] = useState('#f40000');
  const [searchParams] = useSearchParams();
  const brand = searchParams.get('brand');

  const tenantId = useMemo(() => {
    return checkTenantId(brand);
  }, [brand]);
  const [filters, setFilters] = useState<Filters>({
    dressing: data.defaultValues.dressing,
    cameraSettings: {
      angle: data.defaultValues.cameraSettings.angle,
      angleFilters: data.defaultValues.cameraSettings.angleFilters,
      defocusDistance: data.defaultValues.cameraSettings.defocusDistance,
      fstop: data.defaultValues.cameraSettings.fstop,
      HDRIRotation: data.defaultValues.cameraSettings.HDRIRotation,
    },
    lightSettings: {
      keyLightExposure: data.defaultValues.lightSettings.keyExposure,
      HDRILightExposure: data.defaultValues.lightSettings.HDRIExposure,
      keyLight: data.defaultValues.lightSettings.keyLight,
      HDRILighting: data.defaultValues.lightSettings.HDRILighting,
      keyLightHeight: data.defaultValues.lightSettings.keyLightHeight,
      keyLightRotation: data.defaultValues.lightSettings.keyLightRotation,
      HDRI: data.defaultValues.lightSettings.HDRI,
    },
    droplets: data.defaultValues.droplets,
  });
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: user ? `Bearer ${getFromLocalStorage('user')?.acccesToken}` : `Bearer ${accessToken}`,
      context: JSON.stringify(tenantId),
    },
  });
  useEffect(() => {
    (async () => {
      try {
        const response = await axiosInstance.get<{ streamUrl: string }>(`/omniverse/get-streaming-url`);
        const streamUrl = response.data ? response.data.streamUrl : '';
        setDataStreaming(streamUrl);
      } catch (error) {
        console.error('Error:', error);
      }
    })();
  }, [authState]);

  return (
    <section className={styles.asset_generation}>
      <Sidebar
        setImageURL={setImageURL}
        filters={filters}
        setFilters={setFilters}
        dataExport={dataExport}
        setDataExport={setDataExport}
        setAccentColor={setAccentColor}
        accentColor={accentColor}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <Link to="/dashboard" className={styles.back_button}>
            <img src={arrowIcon} alt="back icon" />
            <span>{GlobalStrings.Back}</span>
          </Link>
          <p className={styles.current_date}>
            <Moment format="DD/MM/YYYY" />
          </p>
        </div>
        <div className={styles.iframe_container}>
          <iframe className={styles.iframe} src={dataStreaming} title="Streaming"></iframe>
        </div>
      </div>
      <RightSidebar
        setImageURL={setImageURL}
        imageURL={imageURL}
        filters={filters}
        setFilters={setFilters}
        dataExport={dataExport}
        accentColor={accentColor}
      />
    </section>
  );
};

export default AssetGeneration;
