import React from 'react';
import styles from './InfoMessage.module.scss';

const InfoMessage = (props: { text: string }) => {
  return (
    <div className={styles.message}>
      <p>{props.text}</p>
    </div>
  );
};

export default InfoMessage;
